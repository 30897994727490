import { ACCOUNT_TYPE, StartupProfileCompleteness } from "src/app/core/domain"
import { IBrandDetails, IFeatureUsers, IFeatures } from "src/app/core/domain/brand.model"
import { ACCOUNT_TYPE_TO_DASHBOARD_UTL } from "./navlinks"
import { IProfileDetails } from "src/app/core/domain/profile.model";

export interface INavLinkNew {
  id: string, //unique
  title: string,
  featureKey?: keyof IFeatures,
  accountTypes?: Array<ACCOUNT_TYPE>, // show to all acc type if blank array
  showToNonAuthorizedUser?: boolean,
  iconClass?: string,
  externalUrl?: boolean,
  route?: string
  userKey?: keyof IFeatureUsers;

  isSidebarMenu?: boolean;
  titleKey?: string;
  // iconClass?: string;
  limitedAccess?: boolean,

  onlyApproved?: boolean,
}

export interface INavMenuItem extends INavLinkNew {
  children: Array<INavLinkNew>
}


export const NavMenus: INavMenuItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    children: [],
    accountTypes: [ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER, ACCOUNT_TYPE.PROGRAM_OFFICE, ACCOUNT_TYPE.INDIVIDUAL],
    showToNonAuthorizedUser: false,
    iconClass: 'bi bi-house'
  },


  {
    id: 'search',
    title: 'Connect',
    featureKey: 'show_ecosystem',
    showToNonAuthorizedUser: true,
    limitedAccess: true,
    children: [
      {
        id: 'startup-search',
        title: 'Startups',
        route: '/search/startups',
        showToNonAuthorizedUser: true,
        userKey: 'startups'
      },
      {
        id: 'investor-search',
        title: 'Investors',
        route: '/search/investors',
        // hidden for service provider
        accountTypes: [
          ACCOUNT_TYPE.STARTUP,
          ACCOUNT_TYPE.INVESTOR,
          ACCOUNT_TYPE.CORPORATE,
          ACCOUNT_TYPE.PARTNER,
          ACCOUNT_TYPE.MENTOR,
          ACCOUNT_TYPE.PROGRAM_OFFICE,
        ],
        userKey: 'investors'
      },
      {
        id: 'corporate-search',
        title: 'Corporates',
        route: '/search/corporates',
        userKey: 'corporates'
      },
      {
        id: 'mentor-search',
        title: 'Mentors',
        route: '/search/mentors',
        userKey: 'mentors',
        titleKey: 'mentors_title',
      },
      {
        id: 'service-providers',
        title: 'Service Providers',
        route: '/search/service-providers',
        userKey: 'service_providers',
        titleKey: 'service_providers_title'
      },
      {
        id: 'partners',
        title: 'Partners',
        route: '/search/partners',
        userKey: 'partners',
        titleKey: 'partners_title'
      },
      {
        id: 'program_offices',
        title: 'Program Offices',
        route: '/search/program-offices',
        userKey: 'program_offices',
        titleKey: 'program_offices_title'
      },
      {
        id: 'individuals',
        title: 'Individuals',
        route: '/search/individuals',
        userKey: 'individuals',
        titleKey: 'individuals_title'
      },
    ],
    iconClass: "bi bi-search",
    accountTypes: [ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER, ACCOUNT_TYPE.OTHER,
    ACCOUNT_TYPE.PROGRAM_OFFICE, ACCOUNT_TYPE.INDIVIDUAL,
    ],
  },

  // {
  //   id: 'my-profile',
  //   title: 'My Profile',
  //   children: [],
  //   accountTypes: [ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INDIVIDUAL, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER, ACCOUNT_TYPE.PROGRAM_OFFICE],
  //   showToNonAuthorizedUser: false,
  //   // route: '/profile',
  //   isSidebarMenu: true,
  //   iconClass: 'bi bi-person-circle'
  // },

  {
    id: 'community-feed',
    title: 'Community Wall',
    children: [],
    accountTypes: [
      ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER, ACCOUNT_TYPE.PROGRAM_OFFICE, ACCOUNT_TYPE.INDIVIDUAL],
    showToNonAuthorizedUser: false,
    route: 'community-feed',
    featureKey: 'community_feed',
    iconClass: 'bi bi-people',
    titleKey: 'community_feed_title'
  },


  {
    id: 'engage',
    title: 'My Actions',
    limitedAccess: true,
    children: [
      {
        id: 'calender',
        title: 'My Meetings',
        route: '/calender',
        showToNonAuthorizedUser: false,
        featureKey: 'calendar',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP,
          ACCOUNT_TYPE.INVESTOR,
          ACCOUNT_TYPE.CORPORATE,
          ACCOUNT_TYPE.MENTOR,
          ACCOUNT_TYPE.SERVICE_PROVIDER,
          ACCOUNT_TYPE.PROGRAM_OFFICE,
          ACCOUNT_TYPE.INDIVIDUAL,
          ACCOUNT_TYPE.PARTNER,
        ],
      },



      {
        id: 'my-network',
        title: 'My Connections',
        route: '/connections',
        showToNonAuthorizedUser: false,
        featureKey: 'connections',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP,
          ACCOUNT_TYPE.INVESTOR,
          ACCOUNT_TYPE.CORPORATE,
          ACCOUNT_TYPE.MENTOR,
          ACCOUNT_TYPE.SERVICE_PROVIDER,
          ACCOUNT_TYPE.PROGRAM_OFFICE,
          ACCOUNT_TYPE.INDIVIDUAL,
          ACCOUNT_TYPE.PARTNER,
        ],

      },


      {
        id: 'mentor-hours',
        title: 'My Mentor Hours',
        route: '/mentorship-hours',
        showToNonAuthorizedUser: false,
        featureKey: 'mentor_hours',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP,
          ACCOUNT_TYPE.MENTOR,
          // ACCOUNT_TYPE.PROGRAM_OFFICE,
        ],
        titleKey: 'mentor_hours_title'
      },


      {
        id: 'milestones',
        title: 'My Milestones',
        route: '/milestones',
        showToNonAuthorizedUser: false,
        featureKey: 'milestone_management',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP,
          ACCOUNT_TYPE.INVESTOR,
          ACCOUNT_TYPE.CORPORATE,
          ACCOUNT_TYPE.MENTOR,
          ACCOUNT_TYPE.SERVICE_PROVIDER,
          ACCOUNT_TYPE.PROGRAM_OFFICE,
          ACCOUNT_TYPE.PARTNER,
        ],
        titleKey: 'milestone_management_title'
      },
      {
        id: 'growth-metrics',
        title: 'Growth Metrics',
        route: '/growth-metrics',
        showToNonAuthorizedUser: true,
        featureKey: 'growth_metrics',
        titleKey: 'growth_metrics_title',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP,
          ACCOUNT_TYPE.MENTOR,
          ACCOUNT_TYPE.INVESTOR,
          ACCOUNT_TYPE.CORPORATE,
          ACCOUNT_TYPE.PARTNER,
          // ACCOUNT_TYPE.PROGRAM_OFFICE,
        ],
        onlyApproved: true
      },


      // {
      //   id: 'applied-programs',
      //   title: 'My Applied Programs',
      //   route: '/programs/applied',
      //   showToNonAuthorizedUser: false,
      //   featureKey: 'programs_public_view',
      //   // titleKey: 'growth_metrics_title',
      //   accountTypes: [
      //     ACCOUNT_TYPE.STARTUP,
      //   ],
      // },

      {
        id: 'ip-request',
        title: 'IP Contact Requests',
        route: '/ip-request',
        showToNonAuthorizedUser: false,
        featureKey: 'ip_management',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP,
          // ACCOUNT_TYPE.INVESTOR,
          ACCOUNT_TYPE.CORPORATE,
          // ACCOUNT_TYPE.MENTOR,
          // ACCOUNT_TYPE.SERVICE_PROVIDER,
          // ACCOUNT_TYPE.PROGRAM_OFFICE,

        ],
        // titleKey: 'milestone_management_title'
      },
      {
        id: 'facility-bookings',
        title: 'My Facility Bookings',
        route: '/facilities-management/my-bookings',
        showToNonAuthorizedUser: false,
        featureKey: 'facility_management',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER, ACCOUNT_TYPE.PROGRAM_OFFICE
        ],
        // titleKey: 'milestone_management_title'
      },
    ],
    showToNonAuthorizedUser: true,
    iconClass: 'bi bi-tools'
  },

  {
    id: 'programs',
    title: 'Programs',
    children: [],
    accountTypes: [],
    showToNonAuthorizedUser: false,
    route: 'call-for-applications',
    // featureKey: 'programs_public_view',
    iconClass: 'fa fa-hand-holding-usd',
    titleKey: 'call_for_applications_menu_title'
  },

  // {
  //   id: 'programs',
  //   title: 'Programs',
  //   children: [],
  //   accountTypes: [ACCOUNT_TYPE.STARTUP],
  //   showToNonAuthorizedUser: false,
  //   route: 'programs',
  //   featureKey: 'programs_public_view',
  //   iconClass: 'fa fa-hand-holding-usd',
  //   // titleKey: 'community_feed_title'
  // },

  // {
  //   id: 'programs',
  //   title: 'Programs',
  //   children: [],
  //   accountTypes: [ACCOUNT_TYPE.INDIVIDUAL],
  //   showToNonAuthorizedUser: false,
  //   route: 'vs-programs',
  //   featureKey: 'vs_programs_public_view',
  //   iconClass: 'fa fa-hand-holding-usd',
  //   // titleKey: 'community_feed_title'
  // },



  {
    id: 'events-calender',
    title: 'Events',
    route: '/calender/events',
    showToNonAuthorizedUser: false,
    featureKey: 'events',
    accountTypes: [
      ACCOUNT_TYPE.STARTUP,
      ACCOUNT_TYPE.INVESTOR,
      ACCOUNT_TYPE.CORPORATE,
      ACCOUNT_TYPE.MENTOR,
      ACCOUNT_TYPE.SERVICE_PROVIDER,
      ACCOUNT_TYPE.PARTNER,
      ACCOUNT_TYPE.PROGRAM_OFFICE,
      ACCOUNT_TYPE.INDIVIDUAL,
    ],
    titleKey: 'events_title',
    children: [],
    iconClass: 'bi bi-megaphone'
  },

  {
    id: 'post-job',
    title: 'Jobs',
    accountTypes: [ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.SERVICE_PROVIDER,
    ACCOUNT_TYPE.PROGRAM_OFFICE
    ],
    showToNonAuthorizedUser: false,
    route: 'jobs',
    featureKey: 'jobs',
    limitedAccess: true,
    children: [],
    iconClass: 'bi bi-briefcase'
  },

  {
    id: 'startup-booster-kit',
    title: 'Startup Booster Kit',
    accountTypes: [ACCOUNT_TYPE.STARTUP],
    showToNonAuthorizedUser: false,
    route: '/startup-booster-kit',
    featureKey: 'startup_kit',
    limitedAccess: true,
    children: [],
    iconClass: 'bi bi-currency-dollar'
  },

  // {
  //   id: 'startup-booster-kit',
  //   title: 'Startup Booster Kit',
  //   route: '/startup-booster-kit',
  //   showToNonAuthorizedUser: false,
  //   accountTypes: [ACCOUNT_TYPE.STARTUP,
  //     // ACCOUNT_TYPE.PROGRAM_OFFICE,
  //   ],
  //   featureKey: 'startup_kit',
  //   titleKey: 'startup_kit_title'
  // },

  {
    id: 'resources',
    title: 'Resources',
    children: [

      // {
      //   id: 'challenges',
      //   title: 'Business Opportunities',
      //   route: '/search/challenges',
      //   showToNonAuthorizedUser: false,
      //   featureKey: 'business_challenges',
      //   titleKey: 'business_challenges_title'
      // },


      // {
      //   id: 'post-business',
      //   title: 'Post Challenge',
      //   accountTypes: [ACCOUNT_TYPE.CORPORATE,
      // // ACCOUNT_TYPE.PROGRAM_OFFICE,
      //   ],
      //   showToNonAuthorizedUser: false,
      //   route: 'challenges',
      //   featureKey: 'business_challenges',
      // },



      {
        id: 'legal_template',
        title: 'Contracts & Legal Templates',
        route: '/resources',
        showToNonAuthorizedUser: true,
        featureKey: 'resources_downloads',
        titleKey: 'resources_downloads_title'
      },
      {
        id: 'glossary',
        title: 'Glossary',
        route: '/glossary',
        showToNonAuthorizedUser: true,
        featureKey: 'glossary',
        titleKey: 'glossary_title'
      },

      // {
      //   id: 'tickets',
      //   title: 'Helpdesk',
      //   route: '/tickets',
      //   showToNonAuthorizedUser: false,
      //   featureKey: 'ticket_management',
      //   titleKey: 'ticket_management_title'
      // },
      {
        id: 'reports',
        title: 'Reports',
        route: '/reports',
        showToNonAuthorizedUser: true,
        featureKey: 'reports_downloads',
        titleKey: 'reports_downloads_title'
      },
      // {
      //   id: 'product-updates',
      //   title: 'Platform updates',
      //   route: '/product-updates',
      //   showToNonAuthorizedUser: true,
      //   // featureKey: 'resources_downloads',
      // },
      {
        id: 'deeptech-news',
        title: 'News',
        route: '/news',
        showToNonAuthorizedUser: true,
        featureKey: 'deeptech_news',
        titleKey: 'deeptech_news_title'
      },
      {
        id: 'videos',
        title: 'Videos',
        route: '/videos',
        showToNonAuthorizedUser: true,
        featureKey: 'webinar_videos',
        titleKey: 'webinar_videos_title'
      },
      {
        id: 'ip-search-page',
        title: 'IP Search',
        route: '/search/ips',
        showToNonAuthorizedUser: false,
        featureKey: 'ip_management',
        // titleKey: 'business_challenges_title'
      },
      {
        id: 'facilities-management',
        title: 'Facilities Management',
        route: '/facilities-management',
        showToNonAuthorizedUser: false,
        featureKey: 'facility_management',
        accountTypes: [
          ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER, ACCOUNT_TYPE.PROGRAM_OFFICE
        ],
        // titleKey: 'business_challenges_title'
      },
    ],
    showToNonAuthorizedUser: true,
    iconClass: 'bi bi-book',
    accountTypes: [
      ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER,
      ACCOUNT_TYPE.PROGRAM_OFFICE,
      ACCOUNT_TYPE.INDIVIDUAL
    ],
  },

  // {
  //   id: 'team-members',
  //   title: 'Team Members',
  //   children: [],
  //   accountTypes: [ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE],
  //   showToNonAuthorizedUser: false,
  //   route: '/team/list',
  //   isSidebarMenu: true,
  //   iconClass: 'bi bi-people'
  // },

  {
    id: 'applied-job',
    title: 'My Applied Jobs',
    accountTypes: [ACCOUNT_TYPE.JOB_SEEKER,

    ],
    showToNonAuthorizedUser: false,
    route: '/applied/jobs',
    featureKey: 'jobs',
    iconClass: "bi bi-list-check",
    children: []
  },

  {
    id: 'search-job',
    title: 'Search Jobs',
    accountTypes: [ACCOUNT_TYPE.JOB_SEEKER,
      // ACCOUNT_TYPE.PROGRAM_OFFICE,

    ],
    showToNonAuthorizedUser: false,
    route: '/search/jobs',
    featureKey: 'jobs',
    iconClass: "bi bi-search",
    children: []
  },

  {
    id: 'tickets',
    title: 'Helpdesk',
    children: [],
    showToNonAuthorizedUser: false,
    route: '/tickets',
    isSidebarMenu: true,
    iconClass: 'fa fa-mail-bulk',
    featureKey: 'ticket_management',
    titleKey: 'ticket_management_title'
  },

  {
    id: 'my-account',
    title: 'Account Settings',
    children: [],
    accountTypes: [ACCOUNT_TYPE.STARTUP, ACCOUNT_TYPE.INVESTOR, ACCOUNT_TYPE.CORPORATE, ACCOUNT_TYPE.PARTNER, ACCOUNT_TYPE.MENTOR, ACCOUNT_TYPE.SERVICE_PROVIDER, ACCOUNT_TYPE.JOB_SEEKER,
    ACCOUNT_TYPE.INDIVIDUAL,
    ACCOUNT_TYPE.PROGRAM_OFFICE,
    ],
    showToNonAuthorizedUser: false,
    route: '/account/edit/profile',
    isSidebarMenu: true,
    // featureKey: 'community_feed',
    iconClass: 'bi bi-person'

  },

  {
    id: 'program-office-team',
    title: 'Program office team',
    children: [],
    accountTypes: [ACCOUNT_TYPE.PARTNER,
    ],
    showToNonAuthorizedUser: false,
    route: '/program-office-team',
    isSidebarMenu: true,
    iconClass: 'bi bi-person'
  },
]



export function getNavMenusByUserType(
  userType: ACCOUNT_TYPE, brandDetails: Partial<IBrandDetails>, from: 'header' | 'sidebar' = 'header',
  profileData?: IProfileDetails, profileCompleteness?: StartupProfileCompleteness
) {
  let menus = []
  if (!userType) {
    menus = prepareOthersRoute(false,
      brandDetails,
      profileData,
      profileCompleteness);
  }

  if (userType) {

    switch (userType) {
      case ACCOUNT_TYPE.STARTUP:
        menus = prepareStartUpRoute(
          brandDetails,
          profileData,
          profileCompleteness
        );
        break;

      case ACCOUNT_TYPE.OTHER:
        menus = prepareOthersRoute(false, brandDetails,
          profileData,
          profileCompleteness);
        break;

      default:
        menus = prepareRouteCommon(userType, brandDetails,
          profileData,
          profileCompleteness);
    }
  }
  menus = filterByFeatures(menus, brandDetails, userType)

  menus = filterDiscover(menus, brandDetails, profileData);

  if (from === 'header') {
    menus = menus.filter((e) => !e.isSidebarMenu);
  }
  return menus
}



const isAccesibleForAccountType = (
  item: INavLinkNew, accType: ACCOUNT_TYPE, isLoggedIn = true,
  brandDetails: Partial<IBrandDetails>, profileData?: IProfileDetails, profileCompleteness?: StartupProfileCompleteness
) => {
  let show = !item.accountTypes || !item.accountTypes.length || item.accountTypes.includes(accType);
  if (show && !isLoggedIn && !item.showToNonAuthorizedUser) {
    show = false;
  }

  if (item.limitedAccess && brandDetails?.features?.limited_access && profileCompleteness?.approvalStatus === 'limited_access' && profileCompleteness?.isRejected) {
    show = false
  }

  if (item.onlyApproved && profileCompleteness && !profileCompleteness?.isApproved) {
    show = false
  }

  return show
}

// const

function prepareStartUpRoute(
  brandDetails: Partial<IBrandDetails>,
  profileData?: IProfileDetails, profileCompleteness?: StartupProfileCompleteness
) {
  const navMenus: INavMenuItem[] = JSON.parse(JSON.stringify(NavMenus))
  let items = navMenus.filter((menu) => {
    return isAccesibleForAccountType(menu, ACCOUNT_TYPE.STARTUP, undefined, brandDetails,
      profileData,
      profileCompleteness);
  })
  const dashboardRoute = items.find((e) => e.id === 'dashboard');

  items = items.map((menu) => {
    menu.children = menu.children.filter((submenu) => {
      return isAccesibleForAccountType(submenu, ACCOUNT_TYPE.STARTUP, undefined, brandDetails,
        profileData,
        profileCompleteness);
    })
    return menu
  })

  // set show_dashboard (featureKey) for startup only
  dashboardRoute.featureKey = 'show_dashboard';
  dashboardRoute.route = ACCOUNT_TYPE_TO_DASHBOARD_UTL[ACCOUNT_TYPE.STARTUP]
  return items;
}


function prepareRouteCommon(accType: ACCOUNT_TYPE,
  brandDetails: Partial<IBrandDetails>,
  profileData?: IProfileDetails, profileCompleteness?: StartupProfileCompleteness
) {
  const navMenus: INavMenuItem[] = JSON.parse(JSON.stringify(NavMenus))
  let items = navMenus.filter((menu) => {
    return isAccesibleForAccountType(menu, accType, undefined, brandDetails,
      profileData,
      profileCompleteness);
  })

  items = items.map((menu) => {
    menu.children = menu.children.filter((submenu) => {
      return isAccesibleForAccountType(submenu, accType, undefined, brandDetails,
        profileData,
        profileCompleteness);
    })
    return menu
  })

  const dashboardRoute = items.find((e) => e.id === 'dashboard');
  if (dashboardRoute) {
    dashboardRoute.route = ACCOUNT_TYPE_TO_DASHBOARD_UTL[accType]
  }

  return items;
}

function prepareOthersRoute(isLoggedIn = true,
  brandDetails: Partial<IBrandDetails>,
  profileData?: IProfileDetails, profileCompleteness?: StartupProfileCompleteness
) {
  const navMenus: INavMenuItem[] = JSON.parse(JSON.stringify(NavMenus))

  let items = navMenus.filter((menu) => {
    let show = isAccesibleForAccountType(menu, ACCOUNT_TYPE.OTHER, isLoggedIn, brandDetails,
      profileData,
      profileCompleteness);
    return show
  })


  items = items.map((menu) => {
    menu.children = menu.children.filter((submenu) => {
      return isAccesibleForAccountType(submenu, ACCOUNT_TYPE.OTHER, isLoggedIn, brandDetails,
        profileData,
        profileCompleteness);
    })
    return menu
  })
  return items;
}


function filterDiscover(menus: INavMenuItem[], brandDetails: Partial<IBrandDetails>, profileData: IProfileDetails) {
  menus = JSON.parse(JSON.stringify(menus))

  const discoverMenus = menus.find((e) => e.id === 'search')

  if (discoverMenus) {
    discoverMenus.children = discoverMenus.children.filter((e) => {
      return brandDetails?.users?.[e.userKey]
    })
  }

  if (profileData?.connectionMatrix?.length) {
    const preparedData = {}
    profileData.connectionMatrix.forEach((matrix) => {
      preparedData[matrix.otherProfileType || matrix.profileType2] = {
        canSearch: matrix.canSearch,
        canConnect: matrix.canConnect
      }
    })
    if (discoverMenus?.children) {
      discoverMenus.children = (discoverMenus.children || []).filter((accTypeMenu) => {
        // remove s from end
        const detterminedAccType = accTypeMenu.userKey.substring(0, accTypeMenu.userKey.length - 1)
        if (preparedData[detterminedAccType]) {
          return preparedData[detterminedAccType].canSearch
        } else {
          return true
        }
      })
    }
  }
  return menus
}


function filterByFeatures(menus: INavMenuItem[], brandDetails: Partial<IBrandDetails>, userType: ACCOUNT_TYPE) {
  menus = JSON.parse(JSON.stringify(menus))

  menus = menus.filter((item) => {

    if (item.id === 'dashboard') {
      // ignore for dashboard route
      return true
    }

    let show = (!item.featureKey || brandDetails?.features?.[item.featureKey])
    if (show && !item.route && item.children?.length) {
      item.children = item.children.filter((e) => (!e.featureKey || brandDetails?.features?.[e.featureKey]))


      if (!item.children.length) {
        show = false
      }
    }
    return show
  });

  return menus
}
