import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as GlobalActions from "./core/state/global/global.action";
import * as AdsActions from "./core/state/ads/ads.action";
import * as NotificationsActions from "./core/state/notifications/notifications.action";
import * as ProfileActions from "./core/state/profile/profile.action";
import { getLoggedInUser } from './core/state/auth';
import { Auth, CorporateProfileCompleteness, InvestorProfileCompleteness, MentorProfileCompleteness, ServiceProviderProfileCompleteness, StartupProfileCompleteness } from './core/domain';
import { IGlobalSettings } from './core/domain/settings.model';
import { getBrandDetails, getGlobalSettings } from './core/state/global';
import { Meta } from "@angular/platform-browser";
import { MixpanelService } from './core/service/mixpanel.service';
import { TenantService } from './core/service/tenant.service';
import { ApiEndpointService } from './core/service';
import { ToastAlertService } from './shared/utils/toast.service';
import { FormManagementService } from './core/service/form-management.service';
import { getProfileData } from './core/state/profile';
import { IProfileDetails } from './core/domain/profile.model';
import { IBrandDetails } from './core/domain/brand.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RateExpirenceModalComponent } from './shared/common-components/rate-expirence-modal/rate-expirence-modal.component';
import { SocketService } from './core/service/socket.service';
import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_CONSTANTS } from 'src/CONSTS';
import { ProfileService } from './core/service/profile.service';
import md5 from 'md5';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from './shared/utils/storage.service';
import { AutoApplyProgramOffSecret } from './shared/constants/constants';
import * as moment from 'moment';
declare const gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'sanchiconnect-frontend-angular';
  private wowSubscription!: Subscription;
  faqs: any;
  public getLoggedInUserDetails$: Observable<Auth>;
  private destroyed$: Subject<void> = new Subject();
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;
  tenantLoading = true;
  tenantError = '';
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  brandDetails: Partial<IBrandDetails>;
  notificationsCountInterval = null;
  maintainanceMode = false;
  subscriptionActive = true;
  profileCompleteness: StartupProfileCompleteness | MentorProfileCompleteness | InvestorProfileCompleteness | CorporateProfileCompleteness | ServiceProviderProfileCompleteness;

  constructor(
    private router: Router, private wowService: NgwWowService,
    private route: ActivatedRoute,
    private tenantService: TenantService,
    private toastService: ToastAlertService,
    private formsManagementService: FormManagementService,
    private socketService: SocketService,
    private profileService: ProfileService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    private store: Store, private renderer: Renderer2, private metaService: Meta, private mixPanel: MixpanelService) {

    window.isMobile = this.deviceService.isMobile();
    window.isTablet = this.deviceService.isTablet();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (this.globalSettings?.site?.google_analytics) {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        })
      }
    });


    localStorage.removeItem('inviteData')
    localStorage.removeItem('inviteCode')
    localStorage.removeItem('partner')


    this.route.queryParamMap.subscribe((query) => {
      if (query) {
        let referralCode = query.get('referralCode');
        if (referralCode) {
          localStorage.setItem('referralCode', referralCode)
        }
      }
    })


    this.route.queryParamMap.subscribe((query) => {
      if (query) {
        let program = query.get('program');
        if (program) {
          localStorage.setItem('program', program)
          const autoapply = query.get(StorageService.storageKeys.autoApplyProgram);
          if(autoapply === AutoApplyProgramOffSecret) {
            localStorage.setItem(StorageService.storageKeys.autoApplyProgram, AutoApplyProgramOffSecret)
          }else{
            localStorage.removeItem(StorageService.storageKeys.autoApplyProgram)
          }
        }
        let userType = query.get('userType');
        if (userType) {
          localStorage.setItem('userType-register', userType)
        } else {
          localStorage.removeItem('userType-register')
        }

        let inviteCode = query.get('inviteCode');
        if (inviteCode) {
          localStorage.setItem('inviteCode', inviteCode)
        }
        let partner = query.get('partner');
        if (partner) {
          localStorage.setItem('partner', partner)
        }

        let ratingModal = query.get('rating-modal')
        if (ratingModal) {
          const modal = this.modalService.open(RateExpirenceModalComponent, { size: 'md', scrollable: true, centered: true })
          modal.componentInstance.title = 'Rate your call experience'
        }
      }
    })
    this.profileService.profileCompleteness$
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((profileCompleteness) => {
        if (profileCompleteness) {
          this.profileCompleteness = profileCompleteness;
          this.initCometChat();


          this.formsManagementService.getFormsList(this.profileData.accountType).subscribe((res) => {})

        }
      })

    this.getLoggedInUserDetails$ = this.store.pipe(select(getLoggedInUser));

    this.store.pipe(select(getProfileData)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
        // console.log('this.profileData --->',this.profileData)

        this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((bRes) => {
          if (bRes) {
            setTimeout(() => {
              this.store.dispatch(new ProfileActions.GetProfileTypes())
              if(bRes.features.connections) {
                this.store.dispatch(new GlobalActions.GetBasicConnectionsList())
              }
            }, 2000)


            window.addEventListener("storage", this.storageEventListner);

            // this.socketService.sendMessage('hllloo');

            this.setSocketListners()
          }
        })
      } else {
        if (this.notificationsCountInterval) {
          clearInterval(this.notificationsCountInterval);
        }
        window.removeEventListener("storage", this.storageEventListner);
      }
    })

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
        window.brandDetails = res;
        if (this.brandDetails.maintenance_mode?.maintenance_on) {
          this.maintainanceMode = true
        }

        this.subscriptionActive = this.brandDetails.subscription_active
      }
    })

  }

  storageEventListner(event) {
    if (event.key === 'profile' || event.key === 'user') {
      let localProfile: any = localStorage.getItem('profile')
      if (localProfile) {
        localProfile = JSON.parse(localProfile);
      } else {
        setTimeout(() => {
          window.location.href = '/'
        }, 3000)
      }
      if (this.profileData?.uuid !== localProfile?.uuid) {
        setTimeout(() => {
          window.location.href = '/'
        }, 3000)
      }
    }
  }

  updateFavicon(faviconUrl: string) {
    const head = document.getElementsByTagName('head')[0];
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.type = 'image/x-icon';
    favicon.href = faviconUrl;
    head.appendChild(favicon);
  }
  ngAfterViewInit() {
    // this.hideAppLoader();
  }

  hideAppLoader() {
    let loader = this.renderer.selectRootElement('#pageLoader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  ngOnInit() {
    this.getTenantDetails()
  }

  setSocketListners() {
    if (this.socketService.url && this.profileData) {
      this.socketService.listenToRoom('' + this.profileData.id as any).subscribe((chat) => {
        if (chat) {
          this.store.dispatch(new NotificationsActions.SetNotificationsCount());
        }
      });
    }
  }

  getTenantDetails() {
    this.tenantService.getTenantDetails().subscribe((res) => {
      this.tenantLoading = false;
      if (res.data) {
        ApiEndpointService.setApiEndPoint(res.data.apiUrl);
        this.socketService.setUrl(res.data.apiUrl);
        // ApiEndpointService.setApiEndPoint('http://localhost:3000/');
        // this.socketService.setUrl('http://localhost:3000/');
        this.setSocketListners()
        this.initiateApp();
      }
      this.hideAppLoader();
    }, err => {
      console.log('Tenant errr', err)
      this.tenantLoading = false;
      this.hideAppLoader();
      this.tenantError = err.error?.message || err.message;
      // this.toastService.showToast(this.tenantError || 'Tenant not found', 'error')
    })
  }

  initiateApp() {
    this.mixPanel.init('web-visitor');
    this.store.dispatch(new GlobalActions.GetGlobalSettings())
    this.store.dispatch(new AdsActions.GetAds())

    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        // this.titleService.setTitle(res.websiteName);

        // this.metaService.updateTag({ description: res.websiteDescription })

        console.log("Settingtinging", res)

        this.metaService.addTag({
          name: 'keywords',
          content: res.websiteKeywords
        });


        const r: any = document.querySelector(':root');

        // Create a function for setting a variable value
        function setTheme() {
          // Set the value of variable --blue to another value (in this case "lightblue")
          r.style.setProperty('--color-theme', res.branding.colors.primary);
          r.style.setProperty('--color-secondary', res.branding.colors.secondary);
          r.style.setProperty('--color-certificate', res.certificate.certificatePrimaryColor);
        }

        setTheme()
        // this.updateFavicon('https://images.unsplash.com/photo-1618042164219-62c820f10723?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=20');
        this.updateFavicon(res.imgKitUrl + res.branding.favicon);

        if (res.site.google_analytics) {
          this.injectGoogleAnalyticsScript(res.site.google_analytics);
        }

        this.initCometChat();
      }
    })

    // you can subscribe to WOW observable to react when an element is revealed
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item: HTMLElement) => {
        // do whatever you want with revealed element
      });
    this.setLoggedInUserDetails();

    // this.formsManagementService.getFormsList().subscribe((res) => {
    //   console.log('getFormsList', res)
    // })
  }

  initCometChat() {
    if(this.brandDetails.features.chat_type === "comet_chat"){
      if (this.globalSettings && this.profileData && this.profileCompleteness?.isApproved) {
        const appSetting = new CometChat.AppSettingsBuilder().setRegion(this.globalSettings.cometChat.region).subscribePresenceForAllUsers().build();
        CometChat.init(this.globalSettings.cometChat.appId, appSetting).then(() => {
          this.loginCometChat();
        }).catch(err => {
          console.error('Error initializing comet chat', err);
        })
      }
    }
  }

  loginCometChat() {
    console.log('profileData loginCometChat')
    CometChat.login(this.profileData.uuid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
      (user) => {
        console.log("Login Successful:", { user });
      },
      (error) => {
        if (error.name !== "LOGIN_IN_PROGRESS") {
          console.error('Error while logging in chat', error);
        }
      }
    );
  }


  injectGoogleAnalyticsScript(code: string) {
    const script = document.createElement('script');
    script.async = true;
    script.nonce = 'mvrk6f5gnltrl06mhqbn6nq5qtlk'
    script.src = `https://www.googletagmanager.com/gtag/js?id=${code}`;

    const configScript = document.createElement('script');
    configScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${code}');
    `;

    document.head.appendChild(script);
    document.head.appendChild(configScript);
  }

  setLoggedInUserDetails(): void {
    this.getLoggedInUserDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res?.userId) {
        this.store.dispatch(new NotificationsActions.SetNotificationsCount())
        this.notificationsCountInterval = setInterval(() => {
          this.store.dispatch(new NotificationsActions.SetNotificationsCount())
        }, 30000)
      } else {
        if (this.notificationsCountInterval) {
          clearInterval(this.notificationsCountInterval);
        }
        window.removeEventListener("storage", this.storageEventListner);
      }
    })
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.notificationsCountInterval) {
      clearInterval(this.notificationsCountInterval);
    }
    window.removeEventListener("storage", this.storageEventListner);
    if (this.profileData) {
      this.socketService.leaveRoom(this.profileData.id as any)
    }
  }

  get maintenanceClosed() {
    return localStorage.getItem('maintainance_' + this.brandDetails.maintenance_mode.id) !== 'true'
  }

  handleCloseNotice() {
    localStorage.setItem('maintainance_' + this.brandDetails.maintenance_mode.id, 'true');
  }

  formatMaintenanceTime(time) {
    return moment.utc(time) // Parse as UTC
    .local()                 // Convert to local time
    .format('DD MMMM, YYYY hh:mm a'); // Format as desired
  }
}
