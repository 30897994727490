import { ACCOUNT_TYPE } from "./auth.model";

export interface MeetingsModel {
  date: Date,
  meetingCode: string,
  meetingStatus: string,
  meetingTitle: string,
  meetingsNotes: [],
  participant: IParticipant,
  timeFrom: string,
  timeTo: string,
  uuid: string,
  start: Date,
  end: Date,
  title: string,
  color: string,
  actions: [],
  allDay: true,
  resizable: IResizable,
  draggable: true,
}

export interface IParticipant {
  accountRole: string,
  accountType: "startup"
  avatar: string,
  id: number,
  investorId: number,
  name: string,
  startupId: number,
  uuid: string,
  timeFrom: string,
  timeTo: string
}

export interface IResizable {
  beforeStart: boolean,
  afterEnd: boolean,
}

export enum MeetingTime {
  instant = 'instant',
  schedule_later = 'schedule_later'
}
export interface IMeetingPatchModel {
  otherUserUUID: string;
  date: string;
  timeFrom: string;
  timeTo: string;
  meetingTitle: string;
  meetingDescription?: string
  meetingTimeType: MeetingTime;
  timeZone: string;
  offset: string;
  meetingLocationType? : string;
  meetingToolType? : string;
}

export interface ICalenderEventModal {
  meetingLocationType:string,
  meetingInPersonLocation:string,
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color?: EventColor;
  actions?: EventAction[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  timeFrom?: string;
  timeTo?: string;
  // canDelete?: boolean;
  // canEdit?: boolean;
  uuid?: string;
  date?: string;
  jobApplication?: any;
  user?: {
    id: number
    uuid: string
    accountType: ACCOUNT_TYPE
    accountRole: string
    name: string
    avatar: string
    investorId: number
    startupId: number
    corporateId: number
    mentorId: number
    serviceProviderId: number
    startup: Startup
    investor: Investor
    corporate: Corporate
    mentor: Mentor
    service_provider: ServiceProvider
    companyName: string
    companyUUID: string

    profileUrl?: string;
  },
  receiver: {
    id: number
    uuid: string
    accountType: ACCOUNT_TYPE
    accountRole: string
    name: string
    avatar: string
    investorId: any
    startupId: number
    corporateId: any
    mentorId: number
    serviceProviderId: number
    startup: Startup
    investor: any
    corporate: any
    mentor: Mentor
    service_provider: ServiceProvider
    companyName: string
    companyUUID: string

    profileUrl?: string;
  }
  meetingTitle?: string

  proposedDate: string
  proposedEndTime: string
  proposedStartTime: string

  proposedTimeFrom?: string;
  proposedTimeTo?: string;
  meetingDescription?: string;
  meetingsNotes: [{
    id: number
    notes: string
    uuid: string
    isShared: boolean
  }];

  sharedMeetingNotes: [
    {
      id: number
      uuid: string
      notes: string
      userId: number
      isShared: boolean
    }
  ]

  // canPerformAction?: boolean;
  // canProposeTime?: boolean;

  canPerformAction: boolean
  canProposeTime: boolean
  canJoinMeeting: boolean
  canCreateFollowupMeeting: boolean
  canEdit: boolean
  canDelete: boolean
  canReceiverAcceptReject: boolean
  canReceiverProposeTime: boolean
  proposedNewTime: boolean
  canCreatorAcceptRejectProposedTime: boolean
  isAccepted: boolean,
  isRejected: boolean,

  dateFormatted?: string;
  month?: string

  connectionGroupUUID?: string
  meetingType?: string
}
export interface EventAction {
  id?: string | number;
  label: string;
  cssClass?: string;
  a11yLabel?: string;
  onClick({ event, sourceEvent, }: {
    event: ICalenderEventModal;
    sourceEvent: MouseEvent | KeyboardEvent;
  }): any;
}
export interface EventColor {
  primary: string;
  secondary: string;
}


export enum METTING_AVAILABILITY {
  specific_days = "specific_days",
  temporary_unavailable = 'temporary_unavailable',
  anytime = 'anytime',
  specific_dates = "specific_dates",
}

export interface IMeetingAvailability {
  availabilityHours: METTING_AVAILABILITY
  days: Day[],
  dates: IDate[]

  // for facility management
  availabilitySettings?: METTING_AVAILABILITY
  availabilityTimeType?: 'hour' | 'day'
  leapTime: number
  hourlySlotTime: number
}

export interface IDate {
  closed: boolean
  date:string
  times: Time[]
}

export interface Day {
  closed: boolean
  dayName: string
  dayIndex: number
  times: Time[]
}

export interface Time {
  startTime: string
  endTime: string
}

export interface Startup {
  uuid: string
  companyName: string
}

export interface Mentor {
  uuid: string
  name: string
}

export interface ServiceProvider {
  uuid: string
  name: string
}
export interface Investor {
  uuid: string
  organizationName: string
}

export interface Corporate {
  uuid: string
  companyName: string
}
