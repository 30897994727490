import { ACCOUNT_TYPE } from "src/app/core/domain"
import { PitchDocumentTypes } from "src/app/core/domain/pitch-deck.model"
import { ServicesLookingFor } from "src/app/core/domain/startup.model"

export const PROFILE_NAV_LINKS = {
  INVESTOR_INDIVIDUAL: [
    {
      title: 'Basic Information',
      route: '/investors/edit/individual-details'
    },
    {
      title: 'Investment Thesis',
      route: '/investors/edit/individual-investments-details'
    },
  ],
  [ACCOUNT_TYPE.INDIVIDUAL]: [
    {
      title: 'Basic Information',
      route: '/individuals/edit/individual-information'
    },
  ],
  [ACCOUNT_TYPE.INVESTOR]: [
    {
      title: 'Basic Information',
      route: '/investors/edit/organization-details'
    },
    {
      title: 'Investment Details',
      route: '/investors/edit/investments-details'
    },
    {
      title: 'Representative Details',
      route: '/investors/edit/representative-details'
    },
    // {
    //   title: 'Extra',
    //   route: `/investors/edit/extra`,
    // },
  ],
  [ACCOUNT_TYPE.CORPORATE]: [
    {
      title: 'Basic Information',
      route: '/corporates/edit/corporate-intro'
    },
    {
      title: 'Engagement',
      route: '/corporates/edit/engagement-details',
      featureKey:'corporates_enagement_form'
    },
    // {
    //   title: 'Extra',
    //   route: `/corporates/edit/extra`,
    // },
  ],
  [ACCOUNT_TYPE.MENTOR]: [
    {
      title: 'Basic Information',
      route: '/mentors/edit/mentor-information'
    },
    {
      title: 'Domain Expertise',
      route: '/mentors/edit/industry-technology',
      featureKey: 'mentor_domain_expertise_tab'
      // featureKey: 'industries_technologies_section'
    },
    // {
    //   title: 'Extra',
    //   route: `/mentors/edit/extra`,
    // },
  ],
  [ACCOUNT_TYPE.SERVICE_PROVIDER]: [
    {
      title: 'Basic Information',
      route: '/service-provider/edit/service-provider-information'
    },
    // {
    //   title: 'Industries',
    //   route: '/service-provider/edit/industry-technology'
    // },
    // {
    //   title: 'Extra',
    //   route: `/mentors/edit/extra`,
    // },
  ],
  [ACCOUNT_TYPE.PARTNER]: [
    {
      title: 'Basic Information',
      route: '/partners/edit/partner-information'
    },
    {
      title: 'Industry/Technology',
      route: '/partners/edit/industry-technology',
      featureKey: 'industries_technologies_section'
    },
    // {
    //   title: 'Startups',
    //   route: '/partners/edit/startups'
    // },
  ],
  [ACCOUNT_TYPE.STARTUP]: [
    {
      title: 'Basic Information',
      route: '/startups/edit/startup-information'
    },
    // {
    //   title: 'Team',
    //   route: '/startups/edit/team',
    //   featureKey: 'startup_founders_advisory'
    // },
    {
      title: 'Industry/Technology',
      route: '/startups/edit/industry-technology',
      featureKey: 'industries_technologies_section'
    },

    {
      title: 'Financials',
      route: '/startups/edit/financial-details',
      featureKey: 'financials_form',
    },
    {
      title: 'Pitch Deck',
      route: `/startups/edit/pitch-deck/${PitchDocumentTypes.FUNDRAISING_PITCH}`,
      // service: ServicesLookingFor.fundraising
    },
    // {
    //   title: 'Documents',
    //   route: `/startups/edit/supporting-documents`,
    //   featureKey: 'startup_supporting_documents'
    // },
    // {
    //   title: 'Mentorship',
    //   route: '/startups/edit/mentorship',
    //   featureKey: 'mentor_hours'
    // },
  ],

  [ACCOUNT_TYPE.PROGRAM_OFFICE]: [
    {
      title: 'Basic Information',
      route: '/program-office-members/edit/program-office-member-information'
    },
    // {
    //   title: 'Industry/Technology',
    //   route: '/program-office-members/edit/industry-technology'
    // },
  ],
}


export const ACCOUNT_TYPE_TO_DASHBOARD_UTL = {
  [ACCOUNT_TYPE.STARTUP]: '/startups/dashboard', //
  [ACCOUNT_TYPE.INVESTOR]: '/investors/dashboard', //
  [ACCOUNT_TYPE.CORPORATE]: '/corporates/dashboard', //
  [ACCOUNT_TYPE.PARTNER]: '/partners/dashboard', //
  [ACCOUNT_TYPE.INDIVIDUAL]: '/individuals/dashboard',//
  [ACCOUNT_TYPE.OTHER]: '/search/startups',
  [ACCOUNT_TYPE.JOB_SEEKER]: '/applied/jobs',
  [ACCOUNT_TYPE.MENTOR]: '/mentors/dashboard', //
  [ACCOUNT_TYPE.SERVICE_PROVIDER]: '/service-provider/dashboard',
  [ACCOUNT_TYPE.PROGRAM_OFFICE]: '/program-office-members/dashboard',
}

